<template>
  <div>
    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>
        <b-col cols="12" md="4">
          <b-form-group :label="$t('Ref')" label-for="ref" debounce="500">
            <b-form-input id="ref" v-model="refValue" />
          </b-form-group>
        </b-col>

        <!-- Field: Client -->
        <b-col cols="12" md="4">
          <b-form-group :label="$t('Client')" label-for="client">
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="clients"
              label="name"
              input-id="client"
              v-model="clientValue"
              @search="fetchClientOptions"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="4">
          <b-form-group
            :label="$t('Date')"
            label-for="client"
            style="font-size: 0.857rem"
          >
            <flat-pickr
              id="client"
              v-model="rangeDate"
              :config="{
                mode: 'range',
                dateFormat: 'Y-m-d',
              }"
              class="form-control"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
    <b-card no-body class="mb-0">
      <b-table
        id="scrolle-table"
        ref="refOrderListTable"
        class="position-relative"
        :items="fetchOrders"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('No matching records found')"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            class="btn-icon"
            @click="consulteOrder(data.item)"
          >
            <feather-icon icon="CheckIcon" size="16" />
            <!--Valid return-->
          </b-button>

          <b-modal
            :ref="'consulte-order-' + data.item.id"
            centered
            ok-only
            :ok-title="$t('Arrived')"
            size="xl"
            @hidden="onConsulteOrderModalHidden"
            :title="$t('Consultation')"
            hide-footer
          >
            <b-table
              id="scrolle-table"
              show-empty
              :empty-text="$t('No matching records found')"
              :items="orderLines"
              :fields="fields"
              responsive
              @row-selected="onRowSelected"
            >
              <template #cell(state)="data">
                <!-- <custom-order-line-state-badge :state="data.item.state" /> -->
                {{ data.item.state.name }}
              </template>
              <template #cell(actions)="data">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="primary"
                  class="btn-icon"
                  @click="opemReturnOrderLineModal(data)"
                >
                  <!-- @click="returnOrderLine(data.item)" -->
                  <feather-icon icon="Edit2Icon" size="16" />
                  <!--Valider-->
                </b-button>

                <b-modal
                  :ref="'return-orderline-' + data.index + '-modal'"
                  ok-only
                  ok-title="Ok"
                  centered
                  title="Alert"
                  @hidden="onReturnOrderLineModal"
                  @ok="returnOrderLine"
                >
                  <b-form-group>
                    <label for="available_qty">{{
                      $t("Return Quantity")
                    }}</label>
                    <b-form-input
                      id="available_qty"
                      type="number"
                      v-model="availableQtyValue"
                      :placeholder="$t('Quantity')"
                    />
                  </b-form-group>
                </b-modal>
              </template>
            </b-table>
          </b-modal>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{
              $t("Pagination text", [dataMeta.from, dataMeta.to, dataMeta.of])
            }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalOrders"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BTab,
  BTabs,
  BAlert,
  BLink,
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BPagination,
  BDropdown,
  BDropdownItem,
  BModal,
  VBModal,
  BCardText,
  BBadge,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import instance from "@/libs/axios";
import useReturnTrackingList from "./useReturnTrackingList";
import Ripple from "vue-ripple-directive";
import CustomOrderLineStateBadge from "@/views/ui/CustomOrderLineStateBadge.vue";

export default {
  directives: {
    Ripple,
  },
  components: {
    vSelect,
    CustomOrderLineStateBadge,
    flatPickr,
    BTab,
    BTabs,
    BAlert,
    BLink,
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BPagination,
    BDropdown,
    BDropdownItem,
    BModal,
    VBModal,
    BCardText,
    BBadge,
  },

  async mounted() {
    try {
      const res = await instance.get("/parameters/clients/");
      this.clients = res.data;
    } catch (err) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t("Error"),
          text: err.message,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    }
  },
  data() {
    return {
      fields: [
        { key: "product", label: this.$t("product") },
        { key: "ordered_qty", label: this.$t("ordered qty") },
        { key: "actions", label: this.$t("actions") },
      ],
      clients: [],
      orderLines: [],
      selectedOrderLines: [],
      selectedClient: null,
      timeoutId: null,
      order: null,
      orderline: null,
      quantityValue: null,
      availableQtyValue: null,
    };
  },

  methods: {
    onReturnOrderLineModal() {
      this.availableQtyValue = null;
      this.lotQtyValue = null;
    },
    refreshTable() {
      this.refetchData();
    },
    calcAvailableQty(orderline) {
      return (
        parseFloat(orderline.available_qty) -
        orderline.order_line
          .map((e) => parseFloat(e.available_qty))
          .reduce((a, b) => a + b, 0)
      );
    },
    async returnOrderLine(bvModal) {
      if (!this.availableQtyValue) {
        bvModal.preventDefault();
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Alert"),
            text: this.$t("Return qty is required"),
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        });
        return;
      }
      if (parseFloat(this.availableQtyValue) > this.orderline.ordered_qty) {
        bvModal.preventDefault();
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Alert"),
            text: this.$t("Return qty must be less or equal order qty"),
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        });
        return;
      }
      const resutls = await this.$bvModal.msgBoxConfirm(
        this.$t("Are you sure ?"),
        {
          title: "Alert",
          size: "sm",
          okVariant: "primary",
          okTitle: this.$t("Yes"),
          cancelTitle: this.$t("No"),
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        }
      );

      if (!resutls) {
        return;
      }

      try {
        await instance.patch(`orders/order-lines/${this.orderline.id}`, {
          action: "storehead-return",
          quantity: this.availableQtyValue,
        });

        await this.consulteOrder(this.order);
        let order_id = this.order.id;
        if (this.orderLines.length === 0) {
          this.$refs[`consulte-order-${order_id}`].hide();
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: err.message,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      } finally {
        this.availableQtyValue = null;
        this.lotQtyValue = null;
      }
    },
    opemReturnOrderLineModal(data) {
      this.$refs[`return-orderline-${data.index}-modal`].show();
      this.orderline = data.item;
    },
    async consulteOrder(order) {
      try {
        this.orderLines = [];
        this.order = order;

        const res = await instance.get(`/orders/${order.id}/order-lines/`, {
          params: {
            state_id: "return",
            role: "storekeeper",
          },
        });

        res.data.forEach((orderLine) => {
          const {product_shortcut
          } = orderLine.product_combination;
          const {
            state,
            ordered_qty,
          } = orderLine;
          this.orderLines.push({
            id: orderLine.id,
            product:product_shortcut,
            state: state,
            ordered_qty: ordered_qty,
          });
        });

        this.$refs[`consulte-order-${order.id}`].show();
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: err.message,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    onRowSelected(orderLine) {
      this.selectedOrderLines = orderLine;
    },
    async onConsulteOrderModalHidden() {
      this.refetchData();
      this.orderLines = [];
    },

    fetchClientOptions(search, loading) {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(async () => {
        try {
          loading(true);
          const res = await instance.get("/parameters/clients/", {
            params: { search },
          });
          this.clientsRes = res.data.results;
        } catch (err) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Error"),
              text: err.message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        } finally {
          loading(false);
        }
      }, 500);
    },
  },

  setup(props) {
    const {
      fetchOrders,
      tableColumns,
      perPage,
      currentPage,
      totalOrders,
      dataMeta,
      perPageOptions,
      refValue,
      sortBy,
      isSortDirDesc,
      refOrderListTable,
      refetchData,
      subProfileValue,
      clientValue,
      shippingDateValue,
      emergencyDegreeValue,
      dockValue,
      rangeDate,
    } = useReturnTrackingList();

    return {
      // Sidebar
      fetchOrders,
      tableColumns,
      perPage,
      currentPage,
      totalOrders,
      dataMeta,
      perPageOptions,
      refValue,
      sortBy,
      isSortDirDesc,
      refOrderListTable,
      refetchData,
      subProfileValue,
      clientValue,
      shippingDateValue,
      emergencyDegreeValue,
      dockValue,
      rangeDate,
    };
  },
};
</script>

<style></style>
