<template>
  <div>
    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>
        <!-- Field: Ref -->
        <b-col cols="12" md="4">
          <b-form-group :label="$t('Ref')" label-for="ref" debounce="500">
            <b-form-input id="ref" v-model="refValue" />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
    <b-card no-body class="mb-0">
      <b-table
        id="scrolle-table"
        ref="refOrderListTable"
        class="position-relative"
        :items="fetchOrders"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('No matching records found')"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            class="btn-icon"
            @click="consulteOrder(data.item)"
          >
            <feather-icon icon="EyeIcon" size="16" />
            <!--Consult-->
          </b-button>
          &nbsp;
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            class="btn-icon"
            @click="prepareOrder(data.item, 'ready_in_stock')"
          >
            <feather-icon icon="HomeIcon" size="16" />
            <!--Store-->
          </b-button>
          &nbsp;
          <b-button
          v-if="comptoir_order.includes(data.item.id)"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            class="btn-icon"
            @click="prepareOrder(data.item, 'ready')"
          >
            <feather-icon icon="TruckIcon" size="16" />
            <!--Ready to delivery-->
          </b-button>
          &nbsp;
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            class="btn-icon"
            @click="ChangeOrderInfo(data.item)"
          >
            <feather-icon icon="EditIcon" size="16" />
            <!--Cancel-->
          </b-button>

          <consulte-order
            :ref="'consulteOrder' + data.item.id + 'Ref'"
            :data="data"
            state-id="ready"
          />
          <prepare-order
            :ref="'prepareOrder' + data.item.id + 'Ref'"
            :data="data"
            state-id="ready"
          />
        </template>
      </b-table>
      <b-modal
        ref="delivery-info"
        cancel-variant="outline-secondary"
        no-close-on-backdrop
        :ok-title="$t('Confirm')"
        :cancel-title="$t('Cancel')"
        centered
        @hidden="onDeliveryInfoModalHidden"
        @ok="confirmOrderLineSelected"
        size="m"
        :title="$t('Change delivery info')"
      >
        <b-form>
          <b-row>
            <b-col cols="12">
              <b-form-group
                label-for="delivery-method"
                :label="$t('Shipping type')"
              >
                <v-select
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="deliveryTypes"
                  v-model="selectedShippingType"
                  input-id="delivery-method"
                  label="name"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-modal>
      <b-modal
        ref="by-ligne-client-annexe"
        cancel-variant="outline-secondary"
        no-close-on-backdrop
        :ok-title="$t('Confirm')"
        :cancel-title="$t('Cancel')"
        centered
        @hidden="onDeliveryInfoModalHidden"
        @ok="confirmChange"
        size="lg"
        :title="$t('Change client annexe')"
      >
        <b-form>
          <b-row>
            <b-col cols="12">
              <b-form-group
                label-for="client-annexe-id"
                :label="$t('Client annexe')"
              >
                <v-select
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="clientAnnexe"
                  v-model="selectedClientAnnexe"
                  input-id="client-annexe-id"
                  label="name"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-modal>

      <b-modal
        ref="all-ligne-client-annexe"
        cancel-variant="outline-secondary"
        no-close-on-backdrop
        :ok-title="$t('Confirm')"
        :cancel-title="$t('Cancel')"
        centered
        @hidden="onDeliveryInfoModalHidden"
        @ok="confirmChangeAllLigne"
        size="lg"
        :title="$t('Change client annexe')"
      >
        <b-form>
          <b-row>
            <b-col cols="12">
              <b-form-group
                label-for="client-annexe-id"
                :label="$t('Client annexe')"
              >
                <v-select
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="clientAnnexe"
                  v-model="selectedClientAnnexe"
                  input-id="client-annexe-id"
                  label="name"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-modal>
      <b-modal
        ref="orderline-prepare-line"
        cancel-variant="outline-primary"
        :cancel-title="$t('Cancel')"
        :ok-title="$t('Change')"
        centered
        size="xl"
        :title="$t('Alert')"
        @hidden="onPrepareOrderLineHidden"
        @ok="confirmPreparingOrderLine"
      >
        <b-table
          id="scrolle-table"
          show-empty
          :empty-text="$t('No matching records found')"
          selectable
          select-mode="multi"
          :items="orderLines"
          :fields="fields"
          responsive
          @row-selected="onRowSelected"
        >
          <template #cell(selected)="{ rowSelected }">
            <template v-if="rowSelected">
              <i class="feather icon-disc primary" />
            </template>

            <template v-else>
              <i class="feather icon-circle" />
            </template>
          </template>
        </b-table>
      </b-modal>
      <b-modal
        ref="delivery-info-by-line"
        cancel-variant="outline-secondary"
        no-close-on-backdrop
        :ok-title="$t('Confirm')"
        :cancel-title="$t('Cancel')"
        centered
        @hidden="onDeliveryInfoModalHidden"
        @ok="changeSelectedOrderLines"
        size="m"
        :title="$t('Change delivery info')"
      >
        <b-form>
          <b-row>
            <b-col cols="12">
              <b-form-group
                label-for="delivery-method"
                :label="$t('Shipping type')"
              >
                <v-select
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="deliveryTypes"
                  v-model="selectedShippingType"
                  input-id="delivery-method"
                  label="name"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-modal>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{
              $t("Pagination text", [dataMeta.from, dataMeta.to, dataMeta.of])
            }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalOrders"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BPagination,
  BDropdown,
  BDropdownItem,
  BModal,
  VBModal,
  BCardText,
  BBadge,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import instance from "@/libs/axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import useOrderTrackingList from "./useOrderTrackingList";
import Ripple from "vue-ripple-directive";
import ConsulteOrder from "./ConsulteOrder.vue";
import PrepareOrder from "./PrepareOrder.vue";
export default {
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  components: {
    BButton,
    BDropdown,
    BDropdownItem,
    BMedia,
    BBadge,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
    flatPickr,
    BPagination,
    BModal,
    VBModal,
    BCardText,
    ConsulteOrder,
    PrepareOrder,
  },
  props: {
    clients: Array,
    emergencyDegrees: Array,
    subProfiles: Array,
  },
  data() {
    return {
      fields: [
        { key: "selected", label: this.$t("selected") },
        { key: "product", label: this.$t("product") },
        { key: "ordered_qty", label: this.$t("ordered qty") },
        { key: "piece", label: this.$t("ordered piece") },
        { key: "available_qty", label: this.$t("available qty") },
        { key: "available_piece", label: this.$t("available piece") },
        { key: "shipping_type", label: this.$t("shipping type") },
        { key: "shipping_date", label: this.$t("shipping date") },
        { key: "emergency_degree", label: this.$t("emergency degree") },
      ],
      ref: null,
      selectedSubProfile: null,
      selectedClient: null,
      dateDefault: null,
      selectedEmergencyDegree: null,
      timeoutId: null,
      clientsRes: [],
      emergencyDegreesRes: [],
      selectedShippingDate: null,
      selectedShippingType: null,
      orderLines: [],
      selectedOrderLine: [],
      selectedChangeOrderLine: [],
      deliveryTypes: [],
      clientAnnexe : [],
      selectedClientAnnexe : null,
      selectedShippingType_id:null,
      comptoir_order : [],
    };
  },
  created() {
    this.refreshTable;
    this.getOrderLine();
  },
  methods: {
    async getOrderLine() {
      this.comptoir_order = [];
      const res = await instance.get(`/orders/getReadyOrderLine/`);
      res.data.forEach((orderLine) => {
        if (orderLine.shipping_type.id === 1) {
          this.comptoir_order.push(orderLine.order.id);
        }
      });
    },
    onDeliveryInfoModalHidden() {
      this.selectedShippingDate = null;
      this.selectedShippingType = null;
    },
    onPrepareOrderLineHidden() {
      this.orderLines = [];
    },
    confirmPreparingOrderLine(bvModalEvt) {
      bvModalEvt.preventDefault();
      if (this.selectedOrderLine.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Alert"),
            text: this.$t("You must selecte at least 1 order line."),
            icon: "InfoIcon",
            variant: "warning",
          },
        });
        return;
      }
      this.$refs["delivery-info-by-line"].show();
    },
    onRowSelected(items) {
      this.selectedOrderLine = [];
      this.selectedChangeOrderLine = [];
      this.selectedOrderLine = items;
      this.selectedOrderLine.forEach((orderLine) => {
        this.selectedChangeOrderLine.push(orderLine.id);
      });
    },
    async confirmChange(bvModalEvt){
      try {
          const data = this.selectedChangeOrderLine;
          await instance.post(`/orders/storekeepers/change_delivery_info/`, {
            data: data,
            shipping_type: this.selectedShippingType_id,
            role: "storekeeper",
            client_annexe: this.selectedClientAnnexe.id,
          });

          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Success"),
              text: this.$t("order lines have been updated"),
              icon: "CheckIcon",
              variant: "success",
            },
          });
          this.refetchData();
          this.selectedShippingType = null;
          this.selectedClientAnnexe = null;
          this.$refs[`orderline-prepare-line`].hide();
        } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: err.message,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    async confirmChangeAllLigne(bvModalEvt){
      try {
          await instance.post(`/orders/order-lines/${this.selectedOrderLine.id}`, {
            shipping_type: this.selectedShippingType_id,
            role: "storekeeper_ready",
            client_annexe: this.selectedClientAnnexe.id,
          });

          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Success"),
              text: this.$t("order lines have been updated"),
              icon: "CheckIcon",
              variant: "success",
            },
          });

          this.refreshTable();
          this.selectedShippingType = null;
          this.selectedClientAnnexe = null;

          this.$refs[`orderline-prepare-line`].hide();
        } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: err.message,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },

    async changeSelectedOrderLines(bvModalEvt) {

      if (this.selectedShippingType === null) {
        this.selectedShippingType_id = null;
      } else {
        this.selectedShippingType_id = this.selectedShippingType.id;
      }

      if (this.selectedShippingType_id == 2){
        var client_id = sessionStorage.getItem("client_id");
          const res = await instance.get(`/orders/${client_id}/getClientAnnexe/`);
          this.clientAnnexe = res.data;
          this.$refs[`by-ligne-client-annexe`].show();
      }
      if(this.selectedShippingType_id == 1){
        try {
          const data = this.selectedChangeOrderLine;
          await instance.post(`/orders/storekeepers/change_delivery_info/`, {
            data: data,
            shipping_type: this.selectedShippingType_id,
            role: "storekeeper",
            client_annexe: "comptoir",
          });

          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Success"),
              text: this.$t("Order lines have been updated"),
              icon: "CheckIcon",
              variant: "success",
            },
          });

          this.refetchData();
          this.selectedShippingType = null;
          this.$refs[`orderline-prepare-line`].hide();
        } catch (err) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Error"),
              text: err.message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        }
      }
      if(this.selectedShippingType_id == null){
        try {
          const data = this.selectedChangeOrderLine;
          await instance.post(`/orders/storekeepers/change_delivery_info/`, {
            data: data,
            shipping_type: this.selectedShippingType_id,
            role: "storekeeper",
            client_annexe: null,
          });

          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Success"),
              text: this.$t("Order lines have been updated"),
              icon: "CheckIcon",
              variant: "success",
            },
          });

          this.refetchData();
          this.selectedShippingType = null;
          this.$refs[`orderline-prepare-line`].hide();
        } catch (err) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Error"),
              text: err.message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        }
      }
    },
    async ChangeOrderInfo(orderLine) {
      sessionStorage.setItem("client_id", orderLine.client.id);
      await this.fetchDeliveryMethods();
      this.selectedOrderLine = orderLine;
      this.selectedShippingDate = new Date().toJSON().slice(0, 10);
      const result = await this.$bvModal.msgBoxConfirm(
        this.$t("Please choose the suitable action for you."),
        {
          title: "Alert",
          size: "sm",
          okVariant: "primary",
          okTitle: this.$t("All lines"),
          cancelTitle: this.$t("By line"),
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        }
      );

      if (result === null || result === undefined) {
        return;
      }

      try {
        await this.getOrderlineInfo(orderLine);
        //await this.loadOrderStoreHeads(order);
        if (result) {
          this.$refs["delivery-info"].show();
        } else {
          this.$refs[`orderline-prepare-line`].show();
        }
      } catch (err) {
        const error = err.response
          ? Object.values(err.response.data)[0][0]
          : err.message;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: error ?? this.$t("An error has occurred. Please try again"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
      //this.$refs["delivery-info"].show();
    },
    async getOrderlineInfo(order) {
      this.orderLines = [];
      const res = await instance.get(`/orders/${order.id}/order-lines/`, {
        params: { state_id: "ready", role: "storekeeper" },
      });

      res.data.forEach((orderLine) => {
        const {product_shortcut
        } = orderLine.product_combination;

        const {
          state,
          ordered_qty,
          piece,
          available_qty,
          available_piece,
          shipping_type,
          shipping_date,
          emergency_degree,
        } = orderLine;
        if(emergency_degree!=null){
            var emergency_degree_name = emergency_degree.name;
          }else{
            var emergency_degree_name = null;
          }
        this.orderLines.push({
          id: orderLine.id,
          product:product_shortcut,
          state: state.name,
          ordered_qty: ordered_qty,
          available_qty: available_qty,
          piece: piece,
          available_piece: available_piece,
          shipping_type: shipping_type.name,
          shipping_date: shipping_date,
          emergency_degree:emergency_degree_name,
          selected: true,
        });
      });
    },

    async confirmOrderLineSelected() {
      if (!this.selectedShippingType) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Alert"),
            text: this.$t("Delivery type is required"),
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        });
        return;
      }

      if (this.selectedShippingType === null) {
         this.selectedShippingType_id = null;
      } else {
         this.selectedShippingType_id = this.selectedShippingType.id;
      }

      if (this.selectedShippingType_id == 2){
        var client_id = sessionStorage.getItem("client_id");
          const res = await instance.get(`/orders/${client_id}/getClientAnnexe/`);
          this.clientAnnexe = res.data;
          this.$refs[`all-ligne-client-annexe`].show();
      }
      if(this.selectedShippingType_id == 1){
        await instance.post(`/orders/order-lines/${this.selectedOrderLine.id}`, {
          shipping_type: this.selectedShippingType_id,
          role: "storekeeper_ready",
          client_annexe: "comptoir",
        });
        this.refreshTable();
        this.selectedShippingType = null;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Success"),
            text: this.$t("Order lines have been updated"),
            icon: "CheckIcon",
            variant: "success",
          },
        });
      }
      if(this.selectedShippingType_id == null){
        await instance.post(`/orders/order-lines/${this.selectedOrderLine.id}`, {
          shipping_type: this.selectedShippingType_id,
          role: "storekeeper_ready",
          client_annexe: null,
        });
        this.refreshTable();
        this.selectedShippingType = null;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Success"),
            text: this.$t("Order lines have been updated"),
            icon: "CheckIcon",
            variant: "success",
          },
        });
      }
    },
    async fetchDeliveryMethods() {
      try {
        const res = await instance.get("/parameters/shipping-types/");
        this.deliveryTypes = res.data;
      } catch (err) {
        const error = err.response
          ? Object.values(err.response.data)[0][0]
          : err.message;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: error ?? this.$t("An error has occurred. Please try again"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },

    refreshTable() {
      this.refetchData();
      this.getOrderLine();
    },
    cancelOrder(order) {
      this.$refs[`cancelOrder${order.id}Ref`].cancelOrder(order);
    },
    consulteOrder(order) {
      this.$refs[`consulteOrder${order.id}Ref`].consulteOrder(order);
    },
    prepareOrder(order, state) {
      this.$refs[`prepareOrder${order.id}Ref`].prepareOrder(
        order,
        this.refetchData,
        state
      );
    },
    fetchClientOptions(search, loading) {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(async () => {
        try {
          loading(true);
          const res = await instance.get("/parameters/clients/", {
            params: { search },
          });
          this.clientsRes = res.data.results;
        } catch (err) {
          const error = err.response
            ? Object.values(err.response.data)[0][0]
            : err.message;

          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Error"),
              text: error ?? this.$t("An error has occurred. Please try again"),
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        } finally {
          loading(false);
        }
      }, 500);
    },
    async fetchEmergencyDegreeOptions(search, loading) {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(async () => {
        try {
          loading(true);
          const res = await instance.get("/parameters/emergency-degrees/", {
            params: { search },
          });
          this.emergencyDegreesRes = res.data.results;
        } catch (err) {
          const error = err.response
            ? Object.values(err.response.data)[0][0]
            : err.message;

          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Error"),
              text: error ?? this.$t("An error has occurred. Please try again"),
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        } finally {
          loading(false);
        }
      }, 500);
    },
  },

  setup(props) {
    const {
      fetchOrders,
      tableColumns,
      perPage,
      currentPage,
      totalOrders,
      dataMeta,
      perPageOptions,
      refValue,
      sortBy,
      isSortDirDesc,
      refOrderListTable,
      refetchData,
      subProfileValue,
      clientValue,
      shippingDateValue,
      emergencyDegreeValue,
    } = useOrderTrackingList("ready");

    return {
      // Sidebar
      fetchOrders,
      tableColumns,
      perPage,
      currentPage,
      totalOrders,
      dataMeta,
      perPageOptions,
      refValue,
      sortBy,
      isSortDirDesc,
      refOrderListTable,
      refetchData,
      subProfileValue,
      clientValue,
      shippingDateValue,
      emergencyDegreeValue,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
