<template>
  <div>
    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>
        <!-- Field: Ref -->
        <b-col cols="12" md="4">
          <b-form-group :label="$t('Ref')" label-for="ref" debounce="500">
            <b-form-input id="ref" v-model="refValue" />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
    <b-card no-body class="mb-0">
      <b-table
        id="scrolle-table"
        ref="refOrderListTable"
        class="position-relative"
        :items="fetchOrders"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('No matching records found')"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Actions -->
        <template #cell(actions)="data" style="width: 138px;!important">
          <b-button
            v-if="currentDate === data.item.shipping_date"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            class="btn-icon"
            @click="validateOrderline(data.item)"
          >
            <feather-icon icon="CheckIcon" size="16" />
            <!--Ready-->
          </b-button>
          &nbsp;
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            class="btn-icon margin_button"
            @click="ChangeOrderInfo(data.item)"
          >
            <feather-icon icon="EditIcon" size="16" />
            <!--Cancel-->
          </b-button>

          <consulte-order
            :ref="'consulteOrder' + data.item.id + 'Ref'"
            :data="data"
            state-id="in_preparation"
          />

          <b-modal
            :ok-title="$t('OK')"
            size="lg"
            ok-only
            centered
            title="Alert"
            @hidden="onConfirmOrderLineHidden"
            :ref="'readyOrder' + data.item.id + 'Ref'"
            @ok="confirmOrderLine(data.item)"
          >
            <b-form>
              <b-row>
                <b-col>
                  <b-form-group
                    :label="$t('Change combinaison')"
                    label-for="product"
                  >
                    <v-select
                      style="font-size: 13px"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="productCombinations"
                      label="product_shortcut"
                      v-model="selectedProductCombination"
                      input-id="product"
                      @input="fetchUnity()"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-form-group>
                <label for="text">Available quantity:</label>
                <b-input-group :append="Unity">
                  <b-form-input
                    v-on:keypress="PositiveNumbersOnly"
                    id="email"
                    type="number"
                    v-model="despoQuantity"
                    placeholder="Available quantity"
                  />
                </b-input-group>
              </b-form-group>
              <b-form-group v-if="data.item.product_combination.selling_type.id ===2 " id="dispoPiece">
                <label for="piece">Available Piece:</label>
                <b-form-input
                  v-on:keypress="PositiveNumbersOnly"
                  id="piece"
                  type="number"
                  v-model="despoPiece"
                  placeholder="Piece"
                />
              </b-form-group>
            </b-form>
          </b-modal>
          <b-modal
            ref="by-ligne-client-annexe"
            cancel-variant="outline-secondary"
            no-close-on-backdrop
            :ok-title="$t('Confirm')"
            :cancel-title="$t('Cancel')"
            centered
            @hidden="onDeliveryInfoModalHidden"
            @ok="confirmChange"
            size="lg"
            :title="$t('Change client annexe')"
          >
            <b-form>
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label-for="client-annexe-id"
                    :label="$t('Client annexe')"
                  >
                    <v-select
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="clientAnnexe"
                      v-model="selectedClientAnnexe"
                      input-id="client-annexe-id"
                      label="name"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </b-modal>
        </template>
        <template #cell(photo)="data">
          <a :href="'https://gc.azul-fish.com/'+ data.item.product_combination.photo" :data-fancybox="data.item.product_combination.product_name.name" :data-caption="data.item.product_combination.product_name.name">
            <b-img
                :src="'https://gc.azul-fish.com/'+ data.item.product_combination.photo"
                alt="photo"
                class="image-width"
            />
          </a>
        </template>
      </b-table>
      <b-modal
        ref="delivery-info"
        cancel-variant="outline-secondary"
        no-close-on-backdrop
        :ok-title="$t('Confirm')"
        :cancel-title="$t('Cancel')"
        centered
        @hidden="onDeliveryInfoModalHidden"
        @ok="confirmOrderLineConsultation"
        size="m"
        :title="$t('Change delivery info')"
      >
        <b-form>
          <b-row>
            <b-col cols="12">
              <b-form-group
                label-for="delivery-method"
                :label="$t('Shipping type')"
              >
                <v-select
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="deliveryTypes"
                  v-model="selectedShippingType"
                  input-id="delivery-method"
                  label="name"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-modal>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{
              $t("Pagination text", [dataMeta.from, dataMeta.to, dataMeta.of])
            }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalOrders"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BPagination,
  BDropdown,
  BDropdownItem,
  BModal,
  VBModal,
  BInputGroup,
  BCardText,
  BBadge,
  BImg, 
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import instance from "@/libs/axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import useOrderInPreTrackingList from "./useOrderInPreTrackingList";
import Ripple from "vue-ripple-directive";
import ConsulteOrder from "./ConsulteOrder.vue";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
Fancybox.bind("[data-fancybox]", {
    protect: true,
    Thumbs: {
        type: "classic",
    },
});
export default {
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  components: {
    BButton,
    BDropdown,
    BDropdownItem,
    BMedia,
    BBadge,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
    flatPickr,
    BPagination,
    BModal,
    VBModal,
    BCardText,
    ConsulteOrder,
    BInputGroup,
    BImg, 
  },
  props: {
    clients: Array,
    emergencyDegrees: Array,
    subProfiles: Array,
  },
  data() {
    return {
      currentDate: null,
      deliveryTypes: [],
      Unity: "",
      ref: null,
      emergency_degree: null,
      selectedSubProfile: null,
      selectedClient: null,
      dateDefault: null,
      selectedEmergencyDegree: null,
      timeoutId: null,
      clientsRes: [],
      despoQuantity: null,
      unvailable_qty: null,
      unavailable_piece: null,
      orderline: null,
      despoPiece: null,
      emergencyDegreesRes: [],
      productCombinations: [],
      selected_combinaison_name: null,
      selectedProductCombination: null,
      selectedShippingDate: null,
      selectedShippingType: null,
      fields: [
        { key: "ref", label: this.$t("ref") },
        { key: "client_name", label: this.$t("Client") },
        { key: "emergency_degree", label: this.$t("emergency degree") },
        { key: "product", label: this.$t("product") },
        { key: "ordered_qty", label: this.$t("ordered qty") },
        { key: "piece", label: this.$t("ordered piece") },
        { key: "shipping_type", label: this.$t("shipping type") },
        { key: "shipping_date", label: this.$t("shipping date") },
        { key: "actions", label: this.$t("actions") },
      ],
      clientAnnexe : [],
      selectedClientAnnexe : null,
      shipping_type_id:null,
    };
  },
  created() {
    this.currentDate = new Date().toJSON().slice(0, 10);
  },
  methods: {
    onDeliveryInfoModalHidden() {
      this.selectedShippingDate = null;
      this.selectedShippingType = null;
      this.selectedClientAnnexe = null;
    },
    async ChangeOrderInfo(orderLine) {
      await this.fetchDeliveryMethods();
      this.selectedOrderLine = orderLine;
      this.selectedShippingDate = orderLine.shipping_date;
      this.selectedShippingType = orderLine.shipping_type;
      this.$refs["delivery-info"].show();
    },

    async confirmChange(bvModalEvt){
        await instance.post(`/orders/order-lines/${this.selectedOrderLine.id}`, {
          shipping_date: this.selectedOrderLine.shipping_date,
          shipping_type: this.shipping_type_id,
          role: "storekeeper",
          client_annexe: this.selectedClientAnnexe.id,
        });
        this.refreshTable();
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Success"),
            text: this.$t("Order lines have been updated"),
            icon: "CheckIcon",
            variant: "success",
          },
        });
    },

    async confirmOrderLineConsultation() {
      if (!this.selectedShippingType) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Alert"),
            text: this.$t("Delivery type are required"),
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        });
        return;
      }
      this.shipping_type_id = this.selectedShippingType.id;
      if (this.selectedShippingType.id == 2){
          var client_id = this.selectedOrderLine.order.client.id;
          const res = await instance.get(`/orders/${client_id}/getClientAnnexe/`);
          this.clientAnnexe = res.data;
          this.$refs[`by-ligne-client-annexe`].show();
      }
      
      if(this.selectedShippingType.id == 1){
        await instance.post(`/orders/order-lines/${this.selectedOrderLine.id}`, {
          shipping_date: this.selectedOrderLine.shipping_date,
          shipping_type: this.selectedShippingType.id,
          role: "storekeeper",
          client_annexe: "comptoir",
        });
        this.refreshTable();
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Success"),
            text: this.$t("Order lines have been updated"),
            icon: "CheckIcon",
            variant: "success",
          },
        });
      }
      if(this.selectedShippingType.id == null){
        await instance.post(`/orders/order-lines/${this.selectedOrderLine.id}`, {
          shipping_date: this.selectedOrderLine.shipping_date,
          shipping_type: this.selectedShippingType.id,
          role: "storekeeper",
          client_annexe: null,
        });
        this.refreshTable();
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Success"),
            text: this.$t("Order lines have been updated"),
            icon: "CheckIcon",
            variant: "success",
          },
        });
      }
    },
    async fetchDeliveryMethods() {
      try {
        const res = await instance.get("/parameters/shipping-types/");
        this.deliveryTypes = res.data;
      } catch (err) {
        const error = err.response
          ? Object.values(err.response.data)[0][0]
          : err.message;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: error ?? this.$t("An error has occurred. Please try again"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    
    PositiveNumbersOnly(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    onConfirmOrderLineHidden() {
      this.despoQuantity = null;
      this.despoPiece = null;
      this.selectedProductCombination = [];
      this.unvailable_qty = null;
    },
    refreshTable() {
      this.refetchData();
    },
    async confirmOrderLine(order) {
      if (!this.despoQuantity) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Warning"),
            text: this.$t("Available quantity is required"),
            icon: "InfoIcon",
            variant: "warning",
          },
        });
        return;
      }

      if (this.orderline.selling_type_id === 2) {
        if (!this.despoPiece) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Warning"),
              text: this.$t("Piece is required"),
              icon: "InfoIcon",
              variant: "warning",
            },
          });
          return;
        }
      }

      this.unvailable_qty = null;
      this.unavailable_piece = null;
      if (this.orderline.ordered_qty === null) {
        this.unavailable_piece = this.orderline.piece - this.despoPiece;
      }
      if (this.orderline.piece === null) {
        this.unvailable_qty =
          parseFloat(this.orderline.ordered_qty) -
          parseFloat(this.despoQuantity);
      }
      if (
        this.orderline.piece !== null &&
        this.orderline.ordered_qty !== null
      ) {
        this.unvailable_qty =
          parseFloat(this.orderline.ordered_qty) -
          parseFloat(this.despoQuantity);
        this.unavailable_piece = this.orderline.piece - this.despoPiece;
      }

      if (this.unavailable_piece > 0 || this.unvailable_qty > 0) {
        await instance.patch(`/orders/order-lines/${order.id}/storehead/`, {
          action: "duplicate",
          unvailable_qty: this.unvailable_qty,
          unavailable_piece: this.unavailable_piece,
        });
      }
      
      await instance.post(`/orders/order-lines/storekeeper/${order.id}`, {
        combinaison_id: this.selectedProductCombination.id,
        quantity: this.despoQuantity,
        piece: this.despoPiece,
      });

      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t("Success"),
          text: this.$t("The orderline is ready"),
          icon: "CheckIcon",
          variant: "success",
        },
      });
      this.$refs[`readyOrder${this.orderline.id}Ref`].hide();
      this.refetchData();
      this.despoQuantity = null;
      this.piece = null;
      this.selectedProductCombination = [];
      this.despoPiece = null;
      this.unvailable_qty = null;
    },
    async validateOrderline(orderline) {
      this.orderline = orderline;
      try {
        const res = await instance.get(
          "/parameters/get_relative_combinaison/",
          {
            params: {
              orderline_id: orderline.id,
            },
          }
        );
        this.productCombinations = res.data;
        this.productCombinations.forEach((product) => {
          if (product.id == orderline.product_combination.id) {
            this.Unity = product.unity.name;
            this.selectedProductCombination = product;
          }
        });
      } catch (err) {
        const error = err.response
          ? Object.values(err.response.data)[0][0]
          : err.message;

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: error ?? this.$t("An error has occurred. Please try again"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
      this.$refs[`readyOrder${orderline.id}Ref`].show();
    },
    cancelOrder(order) {
      this.$refs[`cancelOrder${order.id}Ref`].cancelOrder(order);
    },
    consulteOrder(order) {
      this.$refs[`consulteOrder${order.id}Ref`].consulteOrder(order);
    },
    async orderLineReady() {},
    fetchClientOptions(search, loading) {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(async () => {
        try {
          loading(true);
          const res = await instance.get("/parameters/clients/", {
            params: { search },
          });
          this.clientsRes = res.data.results;
        } catch (err) {
          const error = err.response
            ? Object.values(err.response.data)[0][0]
            : err.message;

          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Error"),
              text: error ?? this.$t("An error has occurred. Please try again"),
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        } finally {
          loading(false);
        }
      }, 500);
    },
    async fetchEmergencyDegreeOptions(search, loading) {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(async () => {
        try {
          loading(true);
          const res = await instance.get("/parameters/emergency-degrees/", {
            params: { search },
          });
          this.emergencyDegreesRes = res.data.results;
        } catch (err) {
          const error = err.response
            ? Object.values(err.response.data)[0][0]
            : err.message;

          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Error"),
              text: error ?? this.$t("An error has occurred. Please try again"),
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        } finally {
          loading(false);
        }
      }, 500);
    },
  },

  setup(props) {
    const {
      fetchOrders,
      tableColumns,
      perPage,
      currentPage,
      totalOrders,
      dataMeta,
      perPageOptions,
      refValue,
      sortBy,
      isSortDirDesc,
      refOrderListTable,
      refetchData,
      subProfileValue,
      clientValue,
      shippingDateValue,
      emergencyDegreeValue,
    } = useOrderInPreTrackingList();

    return {
      // Sidebar
      fetchOrders,
      tableColumns,
      perPage,
      currentPage,
      totalOrders,
      dataMeta,
      perPageOptions,
      refValue,
      sortBy,
      isSortDirDesc,
      refOrderListTable,
      refetchData,
      subProfileValue,
      clientValue,
      shippingDateValue,
      emergencyDegreeValue,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
[dir]
  .table.b-table.b-table-selectable:not(.b-table-selectable-no-click)
  > tbody
  > tr {
  height: 62px;
  cursor: pointer;
}
footer#orderbyline___BV_modal_footer_ {
  margin-top: -26px;
}
.image-width{
    width:60px;
}
</style>
