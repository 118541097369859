<template>
  <div>
    <b-modal
      :ref="'consulte-order-' + data.item.id"
      centered
      size="xl"
      @hidden="onConsulteOrderModalHidden"
      :title="$t('Consultation')"
      hide-footer
    >
      <div class="flex_box">
        <table style="width: max-content !important">
          <tbody>
            <tr>
              <td class="color border padding_table">
                <strong>{{ $t("Client") }}</strong>
              </td>
              <td class="border padding_table">{{ client }}</td>
            </tr>
            <tr class="border">
              <td class="color border padding_table">
                <strong>{{ $t("Order source") }}</strong>
              </td>
              <td class="border padding_table">{{ orderSource }}</td>
            </tr>
          </tbody>
        </table>
        <table style="width: max-content !important">
          <tbody>
            <tr>
              <td class="color border padding_table">
                <strong>{{ $t("Order date") }}</strong>
              </td>
              <td class="border padding_table">{{ orderDate }}</td>
            </tr>
            <tr class="border">
              <td class="color border padding_table">
                <strong>{{ $t("Order add by") }}</strong>
              </td>
              <td class="border padding_table">{{ operator }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <b-table
        id="scrolle-table"
        select-mode="multi"
        show-empty
        :empty-text="$t('No matching records found')"
        :items="orderLines"
        :fields="fields"
        responsive
        @row-selected="onRowSelected"
      >
        <template #cell(selected)="{ rowSelected }">
          <template v-if="rowSelected">
            <i class="feather icon-disc primary" />
          </template>

          <template v-else>
            <i class="feather icon-circle" />
          </template>
        </template>
      </b-table>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BPagination,
  BDropdown,
  BDropdownItem,
  BModal,
  VBModal,
  BCardText,
  BBadge,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import instance from "@/libs/axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";

export default {
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  components: {
    BButton,
    BDropdown,
    BDropdownItem,
    BMedia,
    BBadge,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
    flatPickr,
    BPagination,
    BModal,
    VBModal,
    BCardText,
    BFormInvalidFeedback,
  },
  props: {
    data: Object,
    stateId: String,
  },
  data() {
    return {
      orderLines: [],
      storekeepers: [],
      selectedStorekeeper: null,
      selectedOrderLines: [],
      order: null,
      refetchData: null,
      orderDate: null,
      client: null,
      orderSource: null,
      shippingType: null,
      shippingDate: null,
      operator: null,
      fields: [
        { key: "product", label: this.$t("product") },
        { key: "ordered_qty", label: this.$t("ordered qty") },
        { key: "piece", label: this.$t("ordered piece") },
        { key: "available_qty", label: this.$t("available qty") },
        { key: "available_piece", label: this.$t("available piece") },
        { key: "shipping_type", label: this.$t("shipping type") },
        { key: "shipping_date", label: this.$t("shipping date") },
        { key: "emergency_degree", label: this.$t("emergency degree") },
      ],
    };
  },
  methods: {
    onConsulteOrderModalHidden() {
      this.orderLines = [];
    },
    async storeOrderLine(bvModalEvt) {
      bvModalEvt.preventDefault();
      const result = await this.$bvModal.msgBoxConfirm(
        this.$t("Are you sure you want to store the order?"),
        {
          title: "Alert",
          size: "sm",
          okVariant: "primary",
          okTitle: this.$t("Yes"),
          cancelTitle: this.$t("No"),
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        }
      );
      if (!result) {
        return;
      }

      if (this.selectedOrderLines.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Alert"),
            text: this.$t("you must select at least one orderline"),
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        });
        return;
      }

      await instance.post(`/orders/${this.data.item.id}/order-lines/`, {
        order_lines: this.selectedOrderLines.map((e) => e.id),
      });

      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t("Success"),
          text: this.$t("The order has been stored"),
          icon: "CheckIcon",
          variant: "success",
        },
      });
      this.selectedOrderLines = [];
      await this.consulteOrder(this.order, this.refetchData);
    },

    async onRowSelected(orderLine) {
      this.selectedOrderLines = orderLine;
    },

    async consulteOrder(order) {
      this.orderDate = order.date
      this.client = order.client.name;
      if (order.source != null) {
        this.orderSource = order.source.name;
      }
      if (order.operator != null) {
        this.operator = order.operator.name;
      }
      try {
        this.orderLines = [];
        this.order = order;

        const res = await instance.get(`/orders/${order.id}/order-lines/`, {
          params: { state_id: this.stateId, role: "storekeeper" },
        });
        res.data.forEach((orderLine) => {
          const {product_shortcut
          } = orderLine.product_combination;
          const {
            state,
            ordered_qty,
            piece,
            available_qty,
            available_piece,
            shipping_type,
            shipping_date,
            storekeeper_agent,
            delivery_agent,
            emergency_degree,
          } = orderLine;
          if(emergency_degree!=null){
            var emergency_degree_name = emergency_degree.name;
          }else{
            var emergency_degree_name = null;
          }
          this.orderLines.push({
            id: orderLine.id,
            product:product_shortcut,
            state: state.name,
            ordered_qty: ordered_qty,
            available_qty: available_qty,
            piece: piece,
            available_piece: available_piece,
            shipping_type: shipping_type.name,
            shipping_type_id: shipping_type,
            shipping_date: `${shipping_date}`,
            storekeeper: storekeeper_agent?.name,
            delivery_man: delivery_agent?.name,
            emergency_degree: emergency_degree_name,
          });
        });

        this.$refs[`consulte-order-${order.id}`].show();
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: err.message,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
  },
};
</script>
<style>
#baniere_gauche {
  height: 100%;
  float: left;
  top: 0;
  left: 0;
}

#baniere_droite {
  position: relative;
  height: 100%;
  float: right;
  top: 0;
  right: 0;
}
td.color {
  background-color: #f3f2f7;
}
</style>
